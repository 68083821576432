@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Anton&family=Roboto+Mono:ital,wght@0,400;1,300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lusitana&display=swap);
@charset "utf-8";

/* -------Global styles------*/
:root {
  --royalBlue: #5271ff;
  --white: aliceblue;
  --purple: #8c52ff;
  --violet: hsl(260, 84%, 51%);
  --purpleBlue: rgb(71, 51, 255);
  --alfa: "Alfa Slab One", cursive;
  --anton: "Anton", sans-serif;
  --robotoMono: "Roboto Mono", monospace;
  --antic: "Antic Slab", serif;
  --lusita: "Lusitana", serif;
}

/* Reset styles */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Font styles */
.Anton {
  font-family: "Anton", sans-serif;
  font-family: var(--anton);
}

.Alfa {
  font-family: "Alfa Slab One", cursive;
  font-family: var(--alfa);
}

.Antic {
  font-family: "Antic Slab", serif;
  font-family: var(--antic);
}

.Antic_one_five {
  font-family: "Antic Slab", serif;
  font-family: var(--antic);
  line-height: 1.5;
}

/* Html and body styles */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  min-width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
  margin: auto;
}

body {
  min-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

#root {
  min-width: 100%;
}

.Fallback {
  min-height: 100vh;
  min-width: auto;
}

a {
  color: aliceblue;
  color: var(--white);
  text-decoration: none;
  font-family: "Anton", sans-serif;
  font-family: var(--anton);
  letter-spacing: 0.4em;
}

a:hover {
  color: rgb(71, 51, 255);
  color: var(--purpleBlue);
  text-shadow: 1px 1px 15px rgb(150, 139, 253);
}

a.active {
  border-bottom: 2px solid rgb(71, 51, 255);
  border-bottom: 2px solid var(--purpleBlue);
  transition: border-bottom 0.5s ease-in-out;
  text-shadow: 1px 1px 15px rgb(196, 191, 247);
}

img {
  max-width: 100%;
  display: block;
}
/* --------Pages styles------- */
/* Home styles */
.Home {
  min-height: 100vh;
  min-width: 100%;
  background-color: black;
}

.Home_section1 {
  text-align: justify;
  padding: 20px 50px;
  color: aliceblue;
  color: var(--white);
}

.Home_PCont {
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

.Home_parallax1 {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  background-image: url(/static/media/connect.31816dc8.jpg);
  background-position: right;
  background-size: auto;
  background-attachment: fixed;
  min-height: 100%;
  min-width: 100%;
  color: aliceblue;
  color: var(--white);

  transition: all 1s ease;
}

.viewed {
  left: -2050px;
  top: -500px;
  opacity: 0;
  transform: scale(0.1);
}
/* <a href="https://www.freepik.com/photos/business">Business photo created by kjpargeter - www.freepik.com</a> */

.Home_parallax2 {
  background: black;
  min-height: 100vh;
  min-width: 100%;
  color: aliceblue;
  color: var(--white);
}

.Home_parallax3 {
  min-width: 100%;
  min-height: 100vh;
  background-image: url(/static/media/network.dc7a55d1.jpg);
  background-position: center;
  background-size: auto;
  background-attachment: fixed;
  border-top: 2px solid #5271ff;
  border-top: 2px solid var(--royalBlue);
}

.Home_caption {
  width: 100%;
  text-align: center;
  color: rgb(71, 51, 255);
  color: var(--purpleBlue);
}

.Home_caption2 {
  width: 100%;
  min-height: 100%;
  color: rgb(71, 51, 255);
  color: var(--purpleBlue);
}

.Home_caption h2,
h3 {
  font-family: "Alfa Slab One", cursive;
  font-family: var(--alfa);
  font-size: clamp(1.1rem, -0.875rem + 8.333vw, 1.6rem);
  line-height: 2rem;
}

.Home_caption .btn {
  outline: 2px solid hsl(260, 84%, 51%);
  outline: 2px solid var(--violet);
  color: hsl(260, 84%, 51%);
  color: var(--violet);
}

.Caption_span {
  color: #5271ff;
  color: var(--royalBlue);
}

.Home_caption h1 {
  font-family: "Alfa Slab One", cursive;
  font-family: var(--alfa);
  background-color: transparent;
  font-size: clamp(2rem, -0.875rem + 8.333vw, 2.5rem);
  color: aliceblue;
  color: var(--white);
  padding: 18px;
}

.Home_list {
  min-height: 100%;
  width: 100%;
}

.Home_list h3 {
  font-size: clamp(1.8rem, -0.875rem + 8.333vw, 2.5rem);
}

.Home_list_items p {
  font-family: "Lusitana", serif;
  font-family: var(--lusita);
  color: #5271ff;
  color: var(--royalBlue);
  font-size: clamp(1rem, -0.875rem + 8.333vw, 1.5rem);
  line-height: 1.5rem;
}

.Home_list_items {
  line-height: 1.4rem;
}

.Home_images {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.Home_images img {
  max-width: 100%;
  min-width: 50%;
  min-height: auto;
}
.list-group {
  color: hsl(260, 84%, 51%);
  color: var(--violet);
}

.list-group-item-heading {
  overflow-wrap: break-word;
}

.Home_cards {
  min-height: 20vh;
  min-width: 100vw;
}

.Home_card {
  border: 2px solid #5271ff;
  font-family: "Alfa Slab One", cursive;
  font-family: var(--alfa);
  color: aliceblue;
  color: var(--white);
  font-size: clamp(1rem, -0.875rem + 8.333vw, 1.5rem);
  min-height: 100%;
  background-color: rgb(71, 51, 255);
  background-color: var(--purpleBlue);
  background-image: url(/static/media/wave.56aa6a77.png);
  background-size: 100% 90%;
  box-shadow: 1px 1px 8px #8c52ff;
  box-shadow: 1px 1px 8px var(--purple);
}

.Loading {
  min-height: 100vh;
  min-width: 100%;
}

.WebDev {
  min-height: 100vh;
  min-width: 100%;
}

.TemplateHome {
  min-height: 100vh;
  min-width: 100%;
}
.User_TemplateHome {
  min-height: 100vh;
  min-width: 100%;
}

.UserLayout {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
}

.UserLayout_main {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  position: relative;
}

.AdminLayout_main {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  position: relative;
}

.AdminDash {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
}

.Login {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
}

.History {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
}

.Register {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
}

.ForgotPass {
  min-height: 100vh;
  min-width: auto;
  background-color: white;
}

.BlogPost {
  min-height: 100vh;
  min-width: 100%;
}

.BlogHome {
  min-height: 100vh;
  min-width: 100%;
}

.OneProduct {
  min-height: 100vh;
  min-width: 100%;
}

.slider img {
  max-height: 475px;
  object-fit: cover;
}

/* User and Admin Side Nav */
.Sidebar.sidenav---sidenav---_2tBP {
  background: black;
}

.AdminSections {
  min-height: 100vh;
  margin-left: 44px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.UserSections {
  min-height: 100vh;
  margin-left: 44px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.GuestSections {
  min-height: 100vh;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .AdminSections {
    margin: 0;
  }

  .UserSections {
    margin: 0;
  }

  /* Login */
  .Login {
    padding: 0 !important;
  }

  .Login .row {
    padding: 0 0 0 2rem !important;
  }

  .MuiButton-label {
    font-size: clamp(0.3rem, -0.875rem + 8.333vw, 1rem);
  }
}

